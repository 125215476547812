<template>
  <div class="modal-inner-container">
    <div class="w-full">
      <div class="subheadline-large">
        {{ $t('user_delete.support_modal.title') }}
      </div>

      <div class="body-text-large mt-n-4xl">
        {{ $t('user_delete.support_modal.description') }}
      </div>
    </div>

    <div class="w-full mt-n-5xl">
      <ButtonV2
        :inactive="isLoading"
        @onClick="goToSupportPage"
        :label="$t('user_delete.support_modal.talk_to_support')"
        testId="btn-talk-to-support"
        version="secondary"
        wide
      />
      <ButtonV2
        :inactive="isLoading"
        @onClick="sendEmailConfirmation"
        class="mt-n-2xl"
        testId="btn-continue"
        :label="$t('user_delete.support_modal.continue')"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import DELETE_ACCOUNT_STEP_1 from '@/graphql/mutations/DeleteUserAccountStep1.gql';

export default {
  name: 'DeleteUserContactSupport',
  components: {
    ButtonV2,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  async mounted() {},

  methods: {
    goToSupportPage() {
      this.goToInternalPageGlobal('/support');
      this.hideModal();
    },
    async goToNextStep() {
      try {
        this.isLoading = true;
        this.showModal('DeleteAccountStepTwoEmailConfirmation');
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async sendEmailConfirmation() {
      try {
        this.isLoading = true;
        await this.apolloApiCall({
          mutation: DELETE_ACCOUNT_STEP_1,
        });
        this.goToNextStep();
      } catch (err) {
        if(err.error_code === 'invalid_2fa_code') {
          return await this.showModal('DeleteAccountRequire2FA');
        }
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-inner-container {
  height: 460px;
}
</style>
